<template>
  <div>
    <el-row :gutter="6">
      <el-col :span="4">
        <el-input
          placeholder="请输入代理商名称"
          v-model="searchData.companyName"
        ></el-input>
        <!-- <el-select
          @change="handleChange"
          clearable
          v-model="companyNameStr"
          filterable
          placeholder="请选择代理商名称"
        >
          <el-option
            v-for="item in CompanyNameList"
            :key="item.id"
            :label="`${item.id}${
              item.companyName ? `,${item.companyName}` : ''
            }`"
            :value="`${item.id},${item.companyName}`"
          >
          </el-option>
        </el-select> -->
      </el-col>
      <el-col :span="4">
        <el-input
          placeholder="请输入登录账号"
          v-model="searchData.account"
        ></el-input>
      </el-col>
      <!-- <el-col :span="4">
        <el-select v-model="Balance" placeholder="请选择推广总额区间" clearable>
          <el-option
            v-for="item in BalanceOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col> -->
      <!-- <el-col :span="4">
        <el-select
          v-model="businessName"
          placeholder="请选择商务联系人"
          clearable
          @change="changeBusinessName"
        >
          <el-option
            v-for="item in honorManageUser"
            :key="item.id"
            :label="item.name"
            :value="`${item.id},${item.name}`"
          >
          </el-option>
        </el-select>
      </el-col> -->
      <el-col :span="4">
        <el-button type="primary" icon="el-icon-search" @click="handleSearch()">
          查询
        </el-button>
        <ExportTable
          v-has="'download'"
          class="ml10"
          @download="getDownloadList"
        ></ExportTable>
      </el-col>
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
      @customSortChange="customSortChange"
    >
      <template slot-scope="{ index }" slot="itemIndex">
        {{ index + 1 }}
      </template>
      <template slot-scope="{ row }" slot="id">
        <span v-if="row.balanceWarning == 1">
          <i
            class="el-icon-warning"
            style="color: red; font-size: 16px; margin-right: 5px"
          ></i>
        </span>
        <span>{{ row.id }}</span>
      </template>
      <!-- <template slot-scope="{ row }" slot="identityType">
        <span v-if="row.identityType">
          {{ row.identityType | typeDesc(identityTypeList) }}
        </span>
        <span v-else>--</span>
      </template> -->
      <template slot-scope="{ row }" slot="state">
        <el-tag :type="row.state | tagType(devStatusList)">
          {{ row.state | typeDesc(devStatusList) }}
        </el-tag>
      </template>
      <template slot-scope="{ row }" slot="balanceAble">
        {{ row.balanceAble | numberFilter }}
      </template>
      <template slot-scope="{ row }" slot="billableAmount">
        {{ row.billableAmount | numberFilter }}
      </template>
      <!-- <template slot="priceScaleHeader">
        <div style="display: inline-block">
          推广总额<ExplainTip
            content="此数据存在延误，预计10min更新"
            class="ml10"
          ></ExplainTip>
        </div>
      </template> -->
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="{ row }">
          <el-button type="text" @click="lookAppinfo(row)">
            客户列表
          </el-button>
          <el-button type="text" @click="handleView(row)">
            <!-- :disabled="!row.identityType" -->
            认证资料
          </el-button>
          <!-- <el-button
            v-has="'reName'"
            type="text"
            @click="handleResetPassword(row)"
          >
            重置密码
          </el-button> -->
          <!-- <el-button
            v-has="'refund'"
            type="text"
            @click="refundBtn(row)"
            :disabled="row.refunding == 1 || row.balanceAble == 0"
          >
            申请退款
          </el-button> -->
          <!-- <el-button type="text" @click="handleUpdateState(row)">
            <span v-if="row.state == 2">恢复账号</span>
            <span v-else class="colorRed">冻结账号</span>
          </el-button> -->
        </template>
      </el-table-column>
    </PageTable>
    <el-dialog
      title="实名认证资料"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <Detail :detailData="detailData" :imgList="imgList" />
    </el-dialog>
    <!-- <el-dialog
      title="退款申请"
      width="740px"
      :visible.sync="refundVisible"
      :close-on-click-modal="false"
    >
      <Refund :detailData="detailData" @submit="refundSubmit" />
    </el-dialog> -->
  </div>
</template>
<script>
import {
  devStatusList,
  identityTypeList,
  MAXSIZE,
  MAXSIZEDOWN,
} from "@/common/constant/constant.js";
import {
  getDeveloperUserList,
  resetPassword,
  updateState,
  getVerifyInfo,
  requestRefund,
  getAccountBalance,
  getHonorManageUser,
} from "./indexServe.js";
import Detail from "./Detail.vue";
// import Refund from "./Refund.vue";
import PageTable from "@/components/PageTable.vue";
import ExportTable from "@/components/ExportTable.vue";
// import ExplainTip from "@/components/ExplainTip.vue";
export default {
  name: "agentDeveloperList",
  components: { Detail, ExportTable, PageTable },
  data() {
    return {
      dialogVisible: false,
      refundVisible: false,
      devStatusList,
      identityTypeList,
      searchData: { sortType: 0, businessName: "" },
      businessName: "",
      detailData: {},
      companyNameStr: "",
      CompanyNameList: [],
      honorManageUser: [],
      imgList: [], // 认证详情的图片列表
      tableData: {
        list: [],
      },
      Balance: null,
      BalanceOptions: [
        { value: 1, label: "0" },
        { value: 2, label: "1—1000" },
        { value: 3, label: "1001—2000" },
        { value: 4, label: "2000以上" },
      ],
      columns: [
        {
          slot: "itemIndex",
          label: "序号",
          width: 80,
          align: "center",
        },
        {
          slot: "id",
          label: "代理商ID",
        },
        {
          key: "companyName",
          label: "代理商名称",
        },
        {
          key: "account",
          label: "登陆账号",
        },
        // {
        //   slot: "identityType",
        //   label: "类型",
        // },
        // {
        //   slot: "balanceAble",
        //   sortable: true,
        //   labelSlot: "priceScaleHeader",
        //   label: "推广总额",
        // },
        // {
        //   slot: "billableAmount",
        //   sortable: true,
        //   label: "可开票金额",
        // },
        {
          key: "createTime",
          label: "注册时间",
        },
        // {
        //   key: "businessName",
        //   label: "商务",
        // },
        {
          slot: "state",
          label: "状态",
        },
      ],
    };
  },
  created() {
    this.getTableList();
    // this.getHonorManageUserList();
    // this.init();
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    customSortChange(column) {
      if (column) {
        if (column.prop === "balanceAble" && column.order === "ascending") {
          this.searchData.sortType = 1;
        } else if (
          column.prop === "balanceAble" &&
          column.order === "descending"
        ) {
          this.searchData.sortType = 2;
        } else if (
          column.prop === "billableAmount" &&
          column.order === "ascending"
        ) {
          this.searchData.sortType = 3;
        } else if (
          column.prop === "billableAmount" &&
          column.order === "descending"
        ) {
          this.searchData.sortType = 4; //降序
        }
        this.getTableList({ page: 1 });
      }
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleView(row) {
      this.setDialogVisible(true);
      // this.getVerifyInfo(row);
      this.getVerifyInfo({ id: row.identityId });
    },
    lookAppinfo(row) {
      this.$router.push({
        path: "/business/developer/agentCustomerList",
        query: { agentId: row.id },
      });
    },
    handleResetPassword(row) {
      this.$confirm("此操作将重置密码, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          this.resetPassword(row);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    refundBtn(row) {
      getAccountBalance({ id: row.id }).then((res) => {
        if (res.code == 0) {
          this.detailData = { ...row, ...res.data };
          this.refundVisible = true;
        }
      });
    },
    refundSubmit(data) {
      if (data) {
        requestRefund(data).then((res) => {
          if (res.code == 0) {
            this.getTableList();
          }
        });
        this.refundVisible = false;
      } else {
        this.refundVisible = false;
      }
    },
    handleUpdateState(row) {
      let decs = `是否冻结账号${row.account}，冻结后用户将无法使用`;
      let state = 2;
      if (row.state == 2) {
        decs = `是否恢复使用账号${row.account}`;
        state = 1;
      }
      this.$confirm(decs, "提示", {
        type: "warning",
      })
        .then(() => {
          this.updateState({ userId: row.id, state });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async getTableList(params, isDownload) {
      const data = {
        backendType: 2,
        page: 1,
        size: 10,
        ...this.searchData,
        ...params,
      };
      if (this.Balance == 1) {
        data.maxBalance = 0;
        data.minBalance = 0;
      } else if (this.Balance == 2) {
        data.maxBalance = 1000;
        data.minBalance = 1;
      } else if (this.Balance == 3) {
        data.maxBalance = 1001;
        data.minBalance = 2000;
      } else if (this.Balance == 4) {
        data.minBalance = 2000;
      } else {
        data.maxBalance = null;
        data.minBalance = null;
      }
      const res = await getDeveloperUserList(data);
      if (res) {
        // 下载时，不需要更新到table
        if (!isDownload) {
          this.tableData = res.data;
        }
        return res.data;
      }
    },
    async resetPassword(params) {
      const res = await resetPassword(params);
      if (res) {
        if (params.email) {
          this.$alert("新密码已经发送至邮箱账户", "提示");
        } else {
          this.$alert("新密码已经发送至手机", "提示");
        }
        this.getTableList();
      }
    },
    async updateState(params) {
      const res = await updateState(params);
      if (res) {
        this.$message.success("操作成功");
        this.getTableList();
      }
    },
    async getVerifyInfo(params) {
      const res = await getVerifyInfo(params);
      // console.log("res.data", res.data);
      if (res) {
        this.detailData = res.data;
        if (!res.data.identityAdjunctVo) {
          this.detailData.identityAdjunctVo = {};
        }
        const identityAdjunctVo = res.data.identityAdjunctVo;
        const imgList = [];
        if (identityAdjunctVo) {
          for (let key in identityAdjunctVo) {
            imgList.push(identityAdjunctVo[key]);
          }
        }
        this.imgList = imgList;
      }
    },
    setDialogVisible(val) {
      this.dialogVisible = val;
    },
    async getCompanyNameList() {
      const res = await getDeveloperUserList({ size: MAXSIZE, backendType: 2 });
      if (res && res.code === 0 && res.data) {
        this.CompanyNameList = res.data.list || [];
      }
    },
    handleChange(val) {
      this.searchData.companyName = val.split(",")[1];
    },
    changeBusinessName(val) {
      this.searchData.businessName = val.split(",")[1];
    },
    // 其他
    init() {
      this.getCompanyNameList();
    },
    async getHonorManageUserList() {
      const res = await getHonorManageUser({ page: 1, size: MAXSIZE });
      if (res && res.code == 0 && res.data) {
        this.honorManageUser = res.data || [];
      }
    },
    async getDownloadList(callback) {
      const res = await this.getTableList(
        { page: 1, size: MAXSIZEDOWN, backendType: 2 },
        true
      );
      if (res) {
        const headerDisplay = {};
        this.columns.forEach((item) => {
          const key = item.key || item.slot;
          if (key !== "action") {
            headerDisplay[key] = item.label;
          }
        });

        // 处理导出的数据
        const list = res.list.map((i) => ({
          ...i,
          state: this.$options.filters["typeDesc"](i.state, this.devStatusList),
          // identityType: this.$options.filters["typeDesc"](
          //   i.identityType,
          //   this.identityTypeList
          // ),
          // balanceAble: this.$options.filters["numberFilter"](i.balanceAble, ""),
          // billableAmount: this.$options.filters["numberFilter"](
          //   i.billableAmount,
          //   ""
          // ),
        }));
        callback({
          fileName: "代理商平台开发者列表",
          headerDisplay,
          list,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-select {
  width: 100%;
}
</style>
